// Vendors
import Vue from 'vue';
import $ from "jquery";
// Components
import drawer from './components/drawer.vue';
import stickyTop from './components/stickyTop.vue';
import slick from 'vue-slick';
import flatPickr from 'vue-flatpickr-component';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {

  load: {
    key: 'AIzaSyDPV7OiTOASKbtW8rxBuqqWbsNwEpii0z8',
    language: 'nl-BE',
    region: 'be'
  }
});

class App {

  constructor() {
    // jQuery enkel nodig voor Slick Slider / Royal Slider
    window.jQuery = $;
    window.$ = $;
  }

  init() {

    Vue.config.productionTip = false

    new Vue({
      el: '#app',
      delimiters: ['<%', '%>'],
      components: {
        'drawer': drawer,
        'sticky-top': stickyTop,
        // Vendors
        'slick': slick,
        flatPickr,
        'google-map': VueGoogleMaps.Map,
        'gmap-marker' : VueGoogleMaps.Marker
      },
      data: function() {
        return {
          date: null,
          config: {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'd-m-Y',
            altInput: true,
            dateFormat: 'd-m-Y',
            inline: true,
            enable: ["17-04-2025", "18-04-2025", "19-04-2025", "20-04-2025", "25-04-2025", "26-04-2025", "27-04-2025"],
            minDate: "17-04-2025",
            maxDate: "27-04-2025",
            locale: Dutch,
            appendTo: '#flatpickr'
          },
          place: '',
          infoContent: '',
          infoWindowPos: null,
          infoWinOpen: false,
          currentMidx: null,
          infoOptions: {
            pixelOffset: {
              width: 0,
              height: -35
            }
          },
          markers: [
            {
              position: {
                lat: 51.060068,
                lng: 4.189502
              },
              infoText: "Omgangstraat 1, 2880 Mariekerke (Bornem)",
              title: "Kerkberm Mariekerke",
            },
          ],
        }
      },
      methods: {
        openMenu() {
          if (this.$refs.LeftDrawer.active) {
            this.$refs.LeftDrawer.close();
          } else {
            this.$refs.LeftDrawer.open();
          }
        },
        toggleInfoWindow: function (marker, idx) {
          this.infoWindowPos = marker.position;
          this.infoContent = marker.infoText;
          //check if its the same marker that was selected if yes toggle
          if (this.currentMidx == idx) {
            this.infoWinOpen = !this.infoWinOpen;
          }
          //if different marker set infowindow to open and reset current marker index
          else {
            this.infoWinOpen = true;
            this.currentMidx = idx;
          }
        }
      }
    });
  }
}

const app = new App();
app.init();
